import React, { lazy, Suspense } from 'react';
import { Route, Redirect, BrowserRouter as Router, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import ErrorBoundary from './ErrorBoundary';
import Loader from '@iso/components/utility/loader';
import { PUBLIC_ROUTE } from './route.constants';

const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    component: lazy(() => import('./containers/Pages/Home'))
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import('./containers/Pages/Home'))
  },
  {
    path: PUBLIC_ROUTE.PRIVACY_POLICY,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/PrivacyPolicy'))
  },
  {
    path: PUBLIC_ROUTE.CONTACT_US,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/PrivacyPolicy/contact'))
  },
  {
    path: PUBLIC_ROUTE.SUCCESS_PAGE,
    exact: true,
    component: lazy(() => import('@iso/containers/Pages/PrivacyPolicy/success_page'))
  }
];

function PrivateRoute({ children, ...rest }) {
  const isLoggedIn = useSelector((state) => state.Auth.accessToken);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isLoggedIn ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}
PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired
};

function PublicRoute({ children, ...rest }) {
  return <Route {...rest} render={() => children} />;
}
PublicRoute.propTypes = {
  children: PropTypes.node.isRequired
};

export default function Routes() {
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        <Router>
          <Switch>
            <PrivateRoute path="/dashboard">
              <Dashboard />
            </PrivateRoute>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}
          </Switch>
        </Router>
      </Suspense>
    </ErrorBoundary>
  );
}
